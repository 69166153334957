define("portal/adapters/security-deposit", ["exports", "portal/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'v0',

    pathForType() {
      return 'reports/security-deposits';
    }

  });

  _exports.default = _default;
});