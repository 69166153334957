define("portal/helpers/can-delete-item", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canDeleteItem = canDeleteItem;
  _exports.default = void 0;

  function canDeleteItem(params
  /*, hash*/
  ) {
    let booking = params[0];
    let item = params[1];

    if (booking && item) {
      if (item.get('rentalAmount')) {
        return false;
      }

      if (item.get('bundleId')) {
        return false;
      } // integrations have a category


      let category = item.get('category') || '';

      if (category === 'roadside' || category === 'trip-insurance' || category === 'damage-protection') {
        if ((0, _moment.default)().startOf('day').isAfter(booking.get('from')) || booking.status === 'handed_off') {
          return false;
        }
      }

      if (booking.get('siblingBookingGroupId')) {
        if (item.get('constructor.modelName') === 'line-item' && item.get('id')) {
          return false;
        }
      }
    }

    return true;
  }

  var _default = Ember.Helper.helper(canDeleteItem);

  _exports.default = _default;
});