define("portal/models/charge", ["exports", "ember-data", "@outdoorsyco/ember-shared-data/models/charge"], function (_exports, _emberData, _charge) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var attr = _emberData.default.attr;
  var belongsTo = _emberData.default.belongsTo;

  _charge.default.reopen({
    // retryPayout: belongsTo('charge', { async: true, inverse: null }),
    retryPayoutId: attr(),
    attempts: attr('number'),
    stripeId: attr('string'),
    recipient: belongsTo('user', {
      async: true
    }),
    createdBy: belongsTo('user', {
      async: true
    }),
    paymentProcessor: attr(),
    autoDeposit: attr('boolean'),
    paid: attr('boolean'),
    available: attr('simpleDate'),
    canTransfer: attr('boolean'),
    canSync: attr('boolean'),
    adminNote: attr('string'),
    // original charge is converted from a hash to a single number in the serializer
    originalChargeId: attr('number'),
    // Indicates if a payout is allowed to be deleted. This only applies to not processed payouts.
    canDelete: attr('boolean'),
    isChargeType: Ember.computed('type', function () {
      return ['reservation_charge', 'booking_charge', 'remainder_charge', 'manual', 'security_deposit_charge', 'trip_insurance_charge'].indexOf(this.get('type')) !== -1;
    }),
    isTransferType: Ember.computed('type', function () {
      return ['transfer', 'reversal', 'trip_insurance_transfer', 'security_deposit_transfer', 'security_deposit_transfer_reversed'].indexOf(this.get('type')) !== -1;
    }),
    stripeLink: Ember.computed('stripeId', function () {
      let stripeId = this.get('stripeId') || '';

      if (stripeId.indexOf('tr_') === 0) {
        return `https://dashboard.stripe.com/applications/transfers/${stripeId}`;
      }

      if (stripeId.indexOf('po_') === 0) {
        let acctId = this.get('recipient.stripeManagedAccountID');
        return `https://dashboard.stripe.com/${acctId}/payouts/${stripeId}`;
      }

      if (stripeId.indexOf('ch_') === 0) {
        return `https://dashboard.stripe.com/payments/${stripeId}`;
      }

      return `https://dashboard.stripe.com/search?query=${stripeId}`;
    }),
    pendingAutoDeposit: Ember.computed('autoDeposit', 'paid', function () {
      return this.autoDeposit && !this.paid;
    })
  });

  var _default = _charge.default;
  _exports.default = _default;
});