define("portal/components/activity-table/styles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "wrapper": "wrapper_1end6",
    "itemTitle": "itemTitle_1end6",
    "activity": "activity_1end6",
    "header": "header_1end6",
    "headerItem": "headerItem_1end6",
    "changeItemWrapper": "changeItemWrapper_1end6",
    "dataItemWrapper": "dataItemWrapper_1end6",
    "dataItem": "dataItem_1end6",
    "dataItemLabel": "dataItemLabel_1end6",
    "dataItemContent": "dataItemContent_1end6"
  };
  _exports.default = _default;
});