define("portal/utils/activityDiffFinder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.activityDiffFinder = activityDiffFinder;
  _exports.default = void 0;
  const ulStyle = 'list-style-type: none; padding-left: 16px; margin-bottom: 0px;';
  const oldStyle = 'background-color: #D9534F66; width: fit-content;';
  const newStyle = 'background-color: #246B6259; width: fit-content;';

  function activityDiffFinder(activities) {
    const allResults = [];

    function separateOldNew(obj) {
      let oldObj = {};
      let newObj = {}; // All the data comes in one object and the only way to separate the old and new data is to look for the keys
      // that start with "old_" or end with "_old" or are just "old". The same for "new".

      function recursiveSeparate(currentObj, oldAcc, newAcc) {
        for (const [key, value] of Object.entries(currentObj)) {
          if (key.startsWith('old_') || key.endsWith('_old') || key === 'old' || key.endsWith('_from')) {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              oldAcc[key] = {};
              recursiveSeparate(value, oldAcc[key], {});
            } else {
              oldAcc[key] = value;
            }
          } else if (key.startsWith('new_') || key.endsWith('_new') || key === 'new' || key.endsWith('_to')) {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              newAcc[key] = {};
              recursiveSeparate(value, {}, newAcc[key]);
            } else {
              newAcc[key] = value;
            }
          } else {
            // Add the key-value pair to both objects if it's not explicitly old or new
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
              oldAcc[key] = {};
              newAcc[key] = {};
              recursiveSeparate(value, oldAcc[key], newAcc[key]);
            } else {
              oldAcc[key] = value;
              newAcc[key] = value;
            }
          }
        }
      }

      recursiveSeparate(obj, oldObj, newObj);
      return {
        oldObj,
        newObj
      };
    }

    function createHTML(obj1, obj2, isNew = false) {
      // If the object is empty, return an empty string so that we don't render an empty list.
      if (Object.keys(obj1).length === 0) {
        return '';
      }

      let result = `<ul style="${ulStyle}">`;

      for (const key in obj1) {
        if (key === '_super') continue;
        const trimmedKey = key.replace(/_from$|_to$/, '').replace(/^old_|_old$/, '').replace(/^new_|_new$/, '');
        let obj1Value = obj1[key];
        let obj2Value = obj2[key];

        if (typeof obj1Value === 'object' && obj1Value !== null) {
          if (key === 'old') {
            obj2Value = obj2['new'];
          } else if (key === 'new') {
            obj2Value = obj2['old'];
          } else if (key.startsWith('old_') || key.endsWith('_old')) {
            obj1Value = obj1[`old_${trimmedKey}`];
            obj2Value = obj2[`new_${trimmedKey}`];
          } else if (key.startsWith('new_') || key.endsWith('_new')) {
            obj1Value = obj1[`new_${trimmedKey}`];
            obj2Value = obj2[`old_${trimmedKey}`];
          } else if (key.endsWith('_from')) {
            obj1Value = obj1[`${trimmedKey}_from`];
            obj2Value = obj2[`${trimmedKey}_to`];
          } else if (key.endsWith('_to')) {
            obj1Value = obj1[`${trimmedKey}_to`];
            obj2Value = obj2[`${trimmedKey}_from`];
          }

          result += `<li><b>${key}</b>: \n${createHTML(obj1Value, obj2Value, isNew)}</li>\n`;
        } else {
          const keyString = key === 'old' || key === 'new' ? '' : `<b>${key}</b>:`;
          const valueString = `${keyString} ${Ember.Handlebars.Utils.escapeExpression(obj1[key])}`;

          if (obj1[key] !== obj2[key]) {
            result += `<li style="${isNew ? newStyle : oldStyle}">${valueString}</li>\n`;
          } else {
            result += `<li>${valueString}</li>\n`;
          }
        }
      }

      return result + '</ul>';
    }

    for (const activity of activities.toArray()) {
      const separated = separateOldNew(activity.data.meta);
      const oldDataFormatted = createHTML(separated.oldObj, separated.newObj, false);
      const newDataFormatted = createHTML(separated.newObj, separated.oldObj, true);
      const resultData = {
        data: activity,
        oldObj: oldDataFormatted,
        newObj: newDataFormatted
      };
      allResults.push(resultData);
    }

    return allResults;
  }

  var _default = Ember.Helper.helper(activityDiffFinder);

  _exports.default = _default;
});