define("portal/serializers/charge", ["exports", "ember-data", "portal/serializers/application"], function (_exports, _emberData, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EmbeddedRecordsMixin = _emberData.default.EmbeddedRecordsMixin;

  var _default = _application.default.extend(EmbeddedRecordsMixin, {
    // API -> what the model expects
    normalize(typeClass, hash) {
      hash.stripe_token = null;

      if (hash.payment_meta) {
        // transfers and reversals have an `actual_transfer` value to show what was actually moved
        hash.transfer_actual_amount = hash.payment_meta.transfer_actual || 0; // this is unfortunate that payment_meta is now not helpful and turned to a string

        hash.payment_meta = hash.payment_meta ? hash.payment_meta.meta : undefined;
      }

      if (hash.type !== 'reversal' && hash.type !== 'withdrawal' && hash.transfer) {
        hash.transfer_cc_fee = hash.transfer.cc_fee || hash.transfer.refunded_cc_fee;
        hash.transfer_owner_fee = hash.transfer.owner_fee || hash.transfer.refunded_owner_fee;
        hash.transfer_service_fee = hash.transfer.service_fee || hash.transfer.refunded_service_fee;
        hash.transfer_owner_integrations_fee = hash.transfer.owner_integrations_fee || hash.transfer.refunded_owner_integrations_fee;
        hash.transfer_our_fee = hash.transfer.our_fee;
        hash.transfer_outdoorsy_fee = hash.transfer.outdoorsy_fee || hash.transfer.refunded_outdoorsy_fee;
        hash.transfer_deposit_date = hash.transfer.deposit_date;
        hash.transfer_expected_deposit_date = hash.transfer.expected_deposit_date;

        if (hash.transfer.payment_meta) {
          // transfers and reversals have an `actual_transfer` value to show what was actually moved
          hash.transfer_actual_amount = hash.transfer.payment_meta.transfer_actual || 0; // this is unfortunate that payment_meta is now not helpful and turned to a string

          hash.payment_meta = hash.transfer.payment_meta;
        }
      }

      if ((hash.type === 'reversal' || hash.type === 'withdrawal') && hash.payment_meta && hash.payment_meta.transfer_actual) {
        hash.transfer_actual_amount = hash.payment_meta.transfer_actual || 0;
      }

      if (hash.original_charge_id && typeof hash.original_charge_id === 'object') {
        hash.original_charge_id = hash.original_charge_id.id;
      }

      let pp = hash.payment_processor;

      if (pp && pp.id) {
        hash.payment_processor_id = pp.id;
      } // createBy relationship is only set up for admin portal.
      // prevent calls to /users/0 by assigning null.


      if (hash.created_by_id === 0) {
        hash.created_by_id = null;
      }

      return this._super(typeClass, hash);
    },

    // Model -> what the API expects
    serialize(record, options) {
      const data = this._super(record, options);

      const meta = `${data.payment_meta}`;
      data.payment_meta = {
        meta: meta
      };
      return data;
    }

  });

  _exports.default = _default;
});