define("portal/helpers/can-edit-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.canEditItem = canEditItem;
  _exports.default = void 0;

  function canEditItem(params
  /*, hash*/
  ) {
    let booking = params[0];
    let item = params[1];

    if (booking && item) {
      if (!item.get('id')) {
        return true;
      }

      if (booking.get('siblingBookingGroupId')) {
        if (item.get('constructor.modelName') === 'line-item') {
          return false;
        }
      }
    }

    return true;
  }

  var _default = Ember.Helper.helper(canEditItem);

  _exports.default = _default;
});